import tasksMock from './mocks/tasks.json';

const ENDPOINT = 'https://oprf.juice.vk-apps.com';
const DEV_MODE = false;

const __getURL = () => {
    return window.location.href;
};

export const getTasks = () => new Promise(async (resolve) => {
    if (DEV_MODE) {
        return resolve(tasksMock.items);
    }

    const response = await fetch(ENDPOINT + '/get-questions').then((response) => response.json());
    const questions = response.questions.slice(1, response.questions.length).map((x, i) => ({ ...x, id: i + 1 }));
    resolve(questions.filter((x) => x.is_active));
});

export const getProfile = () => new Promise(async (resolve) => {
    const data = new URLSearchParams();
    data.append('url', __getURL());

    const response = await fetch(ENDPOINT + '/get-user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
    }).then((response) => response.json());

    resolve(response.user);
});

export const saveAnswer = (question_id = 0, answer = null) => new Promise(async (resolve, reject) => {
    const data = new URLSearchParams();
    data.append('url', __getURL());
    data.append('question_num', String(question_id));
    data.append('question_value', answer);

    fetch(ENDPOINT + '/save-question', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "success") {
                resolve();
            } else {
                reject();
            }
        })
        .catch((e) => {
            console.error(e);
            reject();
        });
});

export const turnNotifications = (status = 0) => new Promise((resolve) => {
    const data = new URLSearchParams();
    data.append('url', __getURL());
    data.append('notify_status', String(status));

    fetch(ENDPOINT + '/turn-notify', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
    });

    resolve();
});


export const uploadFile = (file, upload_type = 'presentation') => new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('url', __getURL());
    data.append('upload_type', upload_type);

    fetch(ENDPOINT + '/upload-file', {
        method: 'POST',
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "success") {
                resolve(response.link);
            } else {
                if (response.error && response.error[0]) {
                    reject(response.error[0].error);
                } else {
                    reject(null);
                }
            }
        })
        .catch((e) => {
            console.error(e);
            reject(null);
        });
});

export const sendProfile = () => new Promise((resolve, reject) => {
    const data = new URLSearchParams();
    data.append('url', __getURL());

    fetch(ENDPOINT + '/send-profile',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            console.log(response);
            if (response.done) {
                resolve();
            } else {
                reject();
            }
        })
        .catch((e) => {
            console.error(e);
            reject();
        });
});
