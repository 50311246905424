import * as types from './actionTypes';

export const loadProfile = (profile = {}) => {
    return {
        type: types.PROFILE_LOAD,
        profile,
    };
};

export const saveAnswer = (question_id = null, answer) => {
    return {
        type: types.PROFILE_SAVE_ANSWER,
        question_id,
        answer,
    };
};
