import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as API from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, Div, Button, FixedLayout } from '@vkontakte/vkui';
import './index.scss';

import { nextTask, prevTask } from '../../store/tasks/actions';
import { saveAnswer } from '../../store/profile/actions';

import TaskInput from '../../components/TaskInput';
import TaskText from '../../components/TaskText';
import TaskSelect from '../../components/TaskSelect';
import TaskUpload from '../../components/TaskUpload';

const Task = ({ id, go, showLoader, showAlert }) => {
    const dispatch = useDispatch();
    const tasks = useSelector((store) => store.tasks.list);
    const answers = useSelector((store) => store.profile.answers);
    const activeTaskId = useSelector((store) => store.tasks.activeTask);
    const [activeTask, setActiveTask] = useState(null);
    const [answer, setAnswer] = useState(null);

    const goPrevTask = useCallback(() => dispatch(prevTask()), [dispatch]);

    useEffect(() => {
        if (activeTaskId + 1 > tasks.length) {
            go("approval");
        }
        else {
            setActiveTask(tasks[activeTaskId]);
            setAnswer(answers["q" + (activeTaskId + 1)] || null);
        }
    }, [activeTaskId]);

    return (
        <Panel id={id}>
            <PanelHeader>
                Вопрос #{activeTask && activeTask.id}
            </PanelHeader>

            <Div className="task">
                <div className="task__text">
                    {activeTask && activeTask.title}
                </div>
                {{
                    "input": <TaskInput
                        id={activeTask && activeTask.id}
                        answer={answer}
                        format={activeTask && activeTask.format}
                        setAnswer={setAnswer}
                    />,
                    "textarea": <TaskText
                        answer={answer}
                        setAnswer={setAnswer}
                    />,
                    "select": <TaskSelect
                        options={activeTask && activeTask.options && activeTask.options.slice(1, activeTask.options.length).map((x, i) => ({
                            label: x,
                            value: i + 1
                        }))}
                        answer={answer}
                        setAnswer={setAnswer}
                    />,
                    "radio": <TaskSelect
                        options={activeTask && activeTask.options && activeTask.options.slice(1, activeTask.options.length).map((x, i) => ({
                            label: x,
                            value: i + 1
                        }))}
                        answer={answer}
                        setAnswer={setAnswer}
                    />,
                    "upload": <TaskUpload
                        type={activeTask && activeTask.upload_type}
                        answer={answer}
                        setAnswer={setAnswer}
                        ext={activeTask && activeTask.ext}
                        onError={(error) => {
                            if (error === "not_allowed_extension") {
                                showAlert("Ошибка", "Запрещенное расширение файла. Выберите другой");
                            }
                            else if (error === "file_size_too_big") {
                                showAlert("Ошибка", "Очень большой размер файла. Выберите другой");
                            }
                            else {
                                showAlert("Что-то пошло не так", "Попробуйте ещё раз");
                            }
                        }}
                    />
                }[activeTask && activeTask.type]}
            </Div>


            <FixedLayout vertical="bottom" className="task__bottom">
                <Div style={{display: 'flex'}} className="task__footer">
                    <Button
                        size="xl"
                        stretched
                        mode="secondary"
                        style={{ marginRight: 8 }}
                        onClick={activeTaskId === 0 ? () => go("welcome") : goPrevTask}
                    >
                        Назад
                    </Button>
                    <Button
                        size="xl"
                        stretched
                        disabled={answer === null}
                        onClick={() => {
                            const hideLoader = showLoader();
                            dispatch(saveAnswer(activeTask.id, answer));
                            API.saveAnswer(activeTask.id, answer)
                                .then(() => {
                                    dispatch(nextTask());
                                    setAnswer(null);
                                    hideLoader();
                                })
                                .catch(() => {
                                    hideLoader();
                                    showAlert("Что-то пошло не так", "Попробуйте ещё раз");
                                });
                        }}
                    >
                        Далее
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

Task.propTypes = {
    id: PropTypes.string,
    go: PropTypes.func,
};

export default Task;
