import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as API from '../../api';
import bridge from '@vkontakte/vk-bridge';
import { Panel, PanelHeader, Div, FixedLayout, Button } from '@vkontakte/vkui';
import './index.scss';

import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';
import Icon24Notification from '@vkontakte/icons/dist/24/notification';
import Icon24NotificationDisable from '@vkontakte/icons/dist/24/notification_disable';

const Final = ({ id }) => {
    const [isNotifyEnabled, setIsNotifyEnabled] = useState( window.params.vk_are_notifications_enabled === "1");

    const enableNotify = async () => {
        let response;
        if (!isNotifyEnabled) {
            response = await bridge.send("VKWebAppAllowNotifications", {});
        } else {
            response = await bridge.send("VKWebAppDenyNotifications", {});
        }

        if (response.result || response.disabled) {
            setIsNotifyEnabled((prev) => !prev);
        }
    };

    useEffect(() => {
        API.turnNotifications(isNotifyEnabled ? 1 : 0);
    }, [isNotifyEnabled]);

    return (
        <Panel id={id}>
            <PanelHeader>Готово!</PanelHeader>
            <Div className="final">
                <Icon56CheckCircleOutline width="92" height="92" fill="var(--accent)" />
                <h3 style={{ marginTop: 25, marginBottom: 10 }}>Анкета успешно заполнена!</h3>
                <span>Вы можете включить уведомления, чтобы узнать результаты одним из первых.</span>
            </Div>
            {!window.is_ok && (
                <FixedLayout vertical="bottom">
                    <Div className="task__footer">
                        <Button
                            size="xl"
                            mode={!isNotifyEnabled ? "primary" : "secondary"}
                            before={!isNotifyEnabled ? <Icon24Notification/> : <Icon24NotificationDisable/>}
                            onClick={enableNotify}
                        >
                            В{isNotifyEnabled && "ы"}ключить уведомления
                        </Button>
                    </Div>
                </FixedLayout>
            )}
        </Panel>
    );
};

Final.propTypes = {
    id: PropTypes.string,
};

export default Final;
