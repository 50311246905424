import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as API from '../../api';
import { useDispatch } from 'react-redux';
import { Panel, PanelHeader, Group, Div, List, Cell, Header } from '@vkontakte/vkui';

import { saveAnswer } from '../../store/profile/actions';

import Icon28FavoriteOutline from '@vkontakte/icons/dist/28/favorite_outline';

const Welcome = ({ id, go, showLoader, showAlert }) => {
    const dispatch = useDispatch();
    const start = useCallback((nomination) => {
        const hideLoader = showLoader();
        dispatch(saveAnswer(0, nomination));
        API.saveAnswer(0, nomination)
            .then(() => {
                go("task");
                hideLoader();
            })
            .catch(() => {
                hideLoader();
                showAlert("Что-то пошло не так", "Попробуйте ещё раз");
            });
    }, [dispatch]);

    return (
        <Panel id={id}>
            <PanelHeader>
                Номинация
            </PanelHeader>
            <div className="task">
                <Div>
                    <Group header={<Header mode="secondary">Выберите номинацию</Header>}>
                        <List>
                            <Cell
                                expandable
                                multiline
                                before={<Icon28FavoriteOutline fill="var(--accent)" />}
                                description="Лучшие практики популяризации здорового образа жизни на территории Российской Федерации, реализуемые общественниками"
                                onClick={() => start(1)}
                            >
                                Лучшие НКО ЗОЖ
                            </Cell>
                            <Cell
                                expandable
                                multiline
                                before={<Icon28FavoriteOutline fill="var(--accent)" />}
                                description="Лучший учитель физкультуры, реализующий проекты по развитию здорового образа жизни для школьников"
                                onClick={() => start(2)}
                            >
                                Лучшие физруки ЗОЖ
                            </Cell>
                            <Cell
                                expandable
                                multiline
                                before={<Icon28FavoriteOutline fill="var(--accent)" />}
                                description="Лучший волонтер, реализующий проекты по развитию здорового образа жизни"
                                onClick={() => start(3)}
                            >
                                Лучшие волонтеры ЗОЖ
                            </Cell>
                            <Cell
                                expandable
                                multiline
                                before={<Icon28FavoriteOutline fill="var(--accent)" />}
                                description="Лучшие практики популяризации здорового образа жизни на территории Российской Федерации, реализуемые организациями"
                                onClick={() => start(4)}
                            >
                                Лучшие организации ЗОЖ
                            </Cell>
                        </List>
                    </Group>
                </Div>
            </div>
        </Panel>
    );
};

Welcome.propTypes = {
    id: PropTypes.string,
    go: PropTypes.func,
};

export default Welcome;
