import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@vkontakte/vkui';

const templates = {
    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    number: /^\d+$/,
};

const TaskInput = ({ id, answer, format, setAnswer }) => {
    const [ valid, setValid ] = useState(true);
    const [ value, setValue ] = useState(answer);

    const onChange = (e) => {
        const { value: text } = e.currentTarget;
        setValue(text);
        if (format === null || !templates[format]) return setAnswer(text);
        if (templates[format].test(text)) {
            setAnswer(text);
        } else {
            setAnswer(null);
        }
    };

    useEffect(() => {
        setValue(answer);
    }, [id]);

    useEffect(() => {
        if (!(format === null || !templates[format])) {
            if (templates[format].test(value)) {
                setValid(true);
            } else {
                setValid(false);
            }
        }
    }, [value]);

    return (
        <Input
            status={valid ? 'valid' : 'error'}
            onChange={onChange}
            value={value === null ? "" : value}
        />
    );
};

TaskInput.propTypes = {
    id: PropTypes.number,
    answer: PropTypes.string,
    format: PropTypes.string,
    setAnswer: PropTypes.func,
};

TaskInput.defaultProps = {
    format: null
};

export default TaskInput;
