import React from 'react';
import PropTypes from 'prop-types';
import { Textarea } from '@vkontakte/vkui';

const TaskText = ({ answer, setAnswer }) => {
    return (
        <Textarea
            onChange={(e) => setAnswer(e.currentTarget.value || null)}
            value={answer === null ? "" : answer}
        />
    );
};

TaskText.propTypes = {
    answer: PropTypes.string,
    setAnswer: PropTypes.func,
};

export default TaskText;
