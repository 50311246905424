import * as types from './actionTypes';

export const loadTasks = (tasks = []) => {
    return {
        type: types.TASKS_LOAD,
        tasks
    };
};

export const nextTask = () => {
    return {
        type: types.TASKS_NEXT,
    };
};

export const prevTask = () => {
    return {
        type: types.TASKS_PREV,
    };
};

export const openById = (id = 1) => {
    return {
        type: types.TASK_BY_ID,
        id,
    }
};
