import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../api';
import { Panel, PanelHeader, List, Cell, FixedLayout, Button, Div } from '@vkontakte/vkui';

import Icon28CheckCircleOutline from '@vkontakte/icons/dist/28/check_circle_outline';
import Icon28CancelCircleOutline from '@vkontakte/icons/dist/28/cancel_circle_outline';

import { openById } from '../../store/tasks/actions';

const Approval = ({ id, go, showLoader, showAlert }) => {
    const dispatch = useDispatch();
    const answers = useSelector((store) => store.profile.answers);
    const tasks = useSelector((store) => store.tasks.list);

    const [ filled, setFilled ] = useState(true);

    const openTask = (id) => {
        dispatch(openById(id));
        go("task");
    };

    useEffect(() => {
        Object.keys(tasks).forEach((id) => {
            if (!answers["q" + id]) {
                setFilled(false);
            }
        });
    }, [answers]);

    return (
        <Panel id={id}>
            <PanelHeader>Ваши ответы</PanelHeader>
            <div className="task">
                <List>
                    {tasks.map((task) => (
                        <Cell
                            key={task.id}
                            expandable={!answers["q" + task.id]}
                            onClick={() => openTask(task.id)}
                            before={
                                !!answers["q" + task.id] ? (
                                    <Icon28CheckCircleOutline fill="var(--accent)"/>
                                ) : (
                                    <Icon28CancelCircleOutline fill="var(--destructive)"/>
                                )
                            }
                            description={task.title}
                        >
                            Вопрос #{task.id}
                        </Cell>
                    ))}
                </List>
            </div>
            <FixedLayout vertical="bottom" className="task__bottom">
                <Div style={{ display: 'flex' }} className="task__footer">
                    <Button
                        size="xl"
                        disabled={!filled}
                        onClick={() => {
                            const hideLoader = showLoader();
                            API.sendProfile()
                                .then(() => {
                                    hideLoader();
                                    go("final");
                                })
                                .catch(() => {
                                    hideLoader();
                                    showAlert("Что-то пошло не так", "Попробуйте еще раз");
                                })
                        }}
                    >
                        Подтвердить
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

Approval.propTypes = {
    id: PropTypes.string,
};

export default Approval;
