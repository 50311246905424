import * as types from './actionTypes';

const initialState = {
    loaded: false,
    list: [],
    activeTask: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.TASKS_LOAD: {
            const { tasks } = action;
            return {
                ...state,
                loaded: true,
                list: tasks,
                activeTask: 0,
            }
        }

        case types.TASKS_NEXT: {
            return {
                ...state,
                activeTask: state.activeTask + 1,
            };
        }

        case types.TASKS_PREV: {
            return {
                ...state,
                activeTask: state.activeTask - 1,
            }
        }

        case types.TASK_BY_ID: {
            const { id } = action;
            return {
                ...state,
                activeTask: state.list.findIndex((x) => x.id === id) || 0,
            }
        }

        default: return state;
    }
};
