import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View, ScreenSpinner, Alert } from '@vkontakte/vkui';

import Startup from './containers/Startup';
import Welcome from './containers/Welcome';
import Task from './containers/Task';
import Approval from './containers/Approval';
import Final from './containers/Final';

const App = () => {
    const [ activePanel, setActivePanel ] = useState("startup");
    const [ popout, setPopout ] = useState(null);
    const status = useSelector((store) => store.profile.status);

    const go = (newPanel = null) => {
        setActivePanel(newPanel);
    };

    useEffect(() => {
        if (status === 1) {
            setActivePanel("final");
        }
    }, [status]);

    const showAlert = (title = null, text = null) => {
        setPopout(
            <Alert
                actionsLayout="vertical"
                actions={[{
                    title: 'Закрыть',
                    autoclose: true,
                    mode: 'cancel'
                }]}
                onClose={() => setPopout(null)}
            >
                <h2>{title}</h2>
                <p>{text}</p>
            </Alert>
        );
    };

    const showLoader = () => {
        setPopout(<ScreenSpinner/>);
        return () => setPopout(null);
    };

    return (
        <View id="app" activePanel={activePanel} popout={popout}>
            <Startup id="startup" go={go} />
            <Welcome id="welcome" go={go} showLoader={showLoader} showAlert={showAlert} />
            <Task id="task" go={go} showLoader={showLoader} showAlert={showAlert} />
            <Approval id="approval" go={go} showLoader={showLoader} showAlert={showAlert} />
            <Final id="final" go={go} />
        </View>
    );
};

export default App;
