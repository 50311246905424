import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import * as API from '../../api';
import {File, Spinner, Tabs, TabsItem} from '@vkontakte/vkui';
import './index.scss';

import TaskInput from '../TaskInput';

const TaskUpload = ({ type, answer, setAnswer, ext, onError }) => {
    const [ activeTab, setActiveTab ] = useState("uploader");
    const [ loading, setLoading ] = useState(false);

    const upload = (file) => {
        setLoading(true);
        API.uploadFile(file, type)
            .then((link) => {
                setAnswer(link);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                onError(error);
            })
    };

    useEffect(() => {
        setAnswer(" ");
    }, [ext]);

    return (
        <div>
            <Tabs>
                <TabsItem
                    selected={activeTab === "uploader"}
                    onClick={() => setActiveTab("uploader")}
                >
                    Загрузить
                </TabsItem>
                <TabsItem
                    selected={activeTab === "link"}
                    onClick={() => setActiveTab("link")}
                >
                    По ссылке
                </TabsItem>
            </Tabs>
            {activeTab === "uploader" && (
                <div className="upload-form">
                    {loading ? (
                        <div className="upload-form__button">
                            <Spinner/>
                        </div>
                    ) : (
                        <div className="upload-form__button">
                            <File
                                encType="multipart/form-data"
                                mode={answer === null || answer === ' ' ? "primary" : "secondary"}
                                onClick={(e) => {
                                    if (answer !== null && answer !== ' ') {
                                        setAnswer(null);
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    if (answer === null || answer === ' ') {
                                        upload(e.target.files[0]);
                                    }
                                }}
                            >
                                {answer === null || answer === ' ' ? "Выбрать файл" : "Удалить файл"}
                            </File>
                            {ext.length > 0 && (
                                <p style={{ textAlign: 'left', marginTop: 15, color: 'var(--text_secondary)', fontSize: 14 }}>Поддерживаемые форматы: {ext.join(', ')}</p>
                            )}
                        </div>
                    )}
                </div>
            )}
            {activeTab === "link" && <TaskInput answer={answer} setAnswer={setAnswer} />}
        </div>
    );
};

TaskUpload.propTypes = {
    answer: PropTypes.string,
    setAnswer: PropTypes.func,
    onError: PropTypes.func,
    ext: PropTypes.array,
};

TaskUpload.defaultProps = {
    ext: [],
};

export default TaskUpload;
