import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';

import App from './App';
import '@vkontakte/vkui/dist/vkui.css';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
import thunk from 'redux-thunk';

import * as API from './api';
import { loadTasks } from './store/tasks/actions';
import { loadProfile } from './store/profile/actions';

// Store
export const store = createStore(rootReducer, applyMiddleware(thunk));
API.getProfile().then((response) => store.dispatch(loadProfile(response)));
API.getTasks().then((response) => store.dispatch(loadTasks(response)));

// Params
const search = window.location.search.slice(1, window.location.search.length);
window.params = search.split('&').reduce((a, x) => {
    a[x.split("=")[0]] = x.split("=")[1];
    return a;
}, {});

window.is_ok = !!window.params["auth_sig"];

// Init VK  Mini App
bridge.send('VKWebAppInit');

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
