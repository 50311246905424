import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@vkontakte/vkui';

const TaskSelect = ({ options, answer, setAnswer }) => {
    return (
        <div className={window.is_ok ? "task__fix-scroll" : ""}>
            {options.map((option) => (
                <Radio
                    key={option.value}
                    onClick={() => setAnswer(option.value)}
                    checked={option.value === Number(answer)}
                >
                    {option.label}
                </Radio>
            ))}
        </div>
    );
};

TaskSelect.propTypes = {
    options: PropTypes.array,
    answer: PropTypes.string,
    setAnswer: PropTypes.func,
};

TaskSelect.defaultProps = {
    options: [],
};

export default TaskSelect;
