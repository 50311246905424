import * as types from './actionTypes';

const initialState = {
    loaded: false,
    answers: {},
    status: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PROFILE_LOAD: {
            const { profile } = action;
            const answers = Object.keys(profile).reduce((a, x) => {
                if (x.startsWith('q')) {
                    a[x] = profile[x];
                }

                return a;
            }, {});

            return {
                ...state,
                loaded: true,
                answers,
                status: Number(profile.status),
            };
        }

        case types.PROFILE_SAVE_ANSWER: {
            const { question_id, answer } = action;
            return {
                ...state,
                answers: {
                    ...state.answers,
                    ["q" + question_id]: answer,
                },
            };
        }

        default: return state;
    }
};
