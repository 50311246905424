import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelHeader, FixedLayout, Div, Button, Checkbox, Link } from '@vkontakte/vkui';
import './index.scss';

const Startup = ({ id, go }) => {
    const [ checked, setChecked ] = useState(false);
    return (
        <Panel id={id}>
            <PanelHeader>
                Анкета
            </PanelHeader>
            <Div className="startup">
                <div className="startup__image" />
                <h3 className="startup__title">
                    Всероссийский Конкурс «Лучшие практики популяризации здорового
                    образа жизни на территории Российской Федерации»
                </h3>
                <p className="startup__subtitle">
                    Вы можете представить свой проект и победить в одной из номинаций.
                    Не важно, волонтёр ли Вы, учитель по физической культуре, представляете НКО
                    или иную организацию – важно стремление и опыт каждого!
                    <br /><br />
                    В период самоизоляции для всех особенно важно соблюдение принципов ЗОЖ,
                    и у вас есть отличный шанс продвинуть проекты этой сферы онлайн!
                </p>
            </Div>
            <FixedLayout vertical="bottom" className="task__bottom">
                <Div className="task__footer">
                    <Checkbox
                        checked={checked}
                        onChange={() => setChecked((prev) => !prev)}
                        style={{ marginBottom: 5 }}
                    >
                        Я согласен с <Link href="https://www.oprf.ru/1449/2133/2150/2568/2569/" target="_blank">Положением конкурса</Link> и даю своё согласие на обработку/хранение моей персональной информации
                    </Checkbox>
                    <Button
                        size="xl"
                        disabled={!checked}
                        onClick={() => go("welcome")}
                    >
                        Начать
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

Startup.propTypes = {
    id: PropTypes.string,
    go: PropTypes.func,
};

export default Startup;
